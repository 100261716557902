import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Modal,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Autocomplete,
  useMediaQuery,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  List,
  ListItem,
} from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { useColorMode } from '../../theme'
import { useUser } from '../../contexts/UserContext'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined'
import ArticleIcon from '@mui/icons-material/Article'
import FeedbackModal from '../../components/FeedbackModal'
import FileUpload from '../global/FileUpload'
import { addAuditorRun, subscribeToAuditorRuns } from '../../database/services/avaAuditor'
import { updateDocument, uploadFile } from '../../database/databaseUtils'
import { startAvaAuditorRun } from '../../database/services/fetchFairway'

const AvaAuditor = () => {
  const { theme } = useColorMode()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user, company, backendUrl } = useUser()
  const [auditorRuns, setAuditorRuns] = useState([])
  const [loading, setLoading] = useState(true)
  const [formOpen, setFormOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Upload documents']
  const [allFiles, setAllFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false)
  const [rationaleModalOpen, setRationaleModalOpen] = useState(false)
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const [selectedRun, setSelectedRun] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const getStatusColor = (status) => {
    switch (status) {
      case 'Done':
        return '#00FF00'
      case 'Failed':
      case 'Error':
        return '#FF0000'
      case 'In Progress':
      case 'Created':
        return '#FFFF00'
      default:
        return '#FFFF00'
    }
  }

  const actionIcons = {
    viewDocument: { tooltip: 'View Document', icon: <ArticleIcon /> },
    rationale: { tooltip: 'View details', icon: <VisibilityIcon /> },
    leaveFeedback: { tooltip: 'Leave Feedback', icon: <ReviewsOutlinedIcon /> },
  }

  const auditorRunsColumns = [
    {
      field: 'name',
      headerName: 'File Name',
      width: 150,
      headerAlign: 'center',
    },
    {
      field: 'organization_name',
      headerName: 'Organization Name',
      width: 200,
      headerAlign: 'center',
    },
    {
      field: 'state',
      headerName: 'State/County',
      width: 200,
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 165,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Box
            sx={{
              width: 10,
              height: 10,
              bgcolor: getStatusColor(params.value),
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: 1,
            }}
          />
          <Typography variant="body2">{params.value}</Typography>
        </Box>
      ),
    },
    {
      field: 'approved',
      headerName: 'Decision',
      width: 120,
      headerAlign: 'center',
    },
    {
      field: 'utilities',
      headerName: 'Utilities',
      width: 160,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex">
          {Object.keys(actionIcons).map((action) => (
            <Tooltip key={action} title={actionIcons[action].tooltip}>
              <IconButton
                onClick={() => handleAction(action, params.row)}
                disabled={action === 'rationale' && !params.row.rationale}
              >
                {actionIcons[action].icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    },
    {
      field: 'user_name',
      headerName: 'User Name',
      width: 170,
      headerAlign: 'center',
    },
    { field: 'vin', headerName: 'VIN', width: 170, headerAlign: 'center' },
    {
      field: 'run_datetime',
      headerName: 'Run Time',
      width: 190,
      headerAlign: 'center',
    },
  ]

  const handleAction = (action, row) => {
    switch (action) {
      case 'viewDocument':
        handleDocumentsView(row)
        break
      case 'rationale':
        handleRationaleView(row)
        break
      case 'leaveFeedback':
        handleActionView(action, row)
        break
      default:
        break
    }
  }

  const handleActionView = (action, row) => {
    switch (action) {
      case 'leaveFeedback':
        setSelectedRun(row)
        setFeedbackModalOpen(true)
        break

      default:
        console.error(`Action not implemented: ${action}`)
        alert('Action not implemented')
    }
  }

  const handleFeedbackCloseModal = () => {
    setFeedbackModalOpen(false)
    setSelectedRun(null)
  }

  const handleRationaleView = (row) => {
    setSelectedRun(row)
    setRationaleModalOpen(true)
  }

  const handleRationaleCloseModal = () => {
    setRationaleModalOpen(false)
    setSelectedRun(null)
  }

  const handleDocumentsView = (row) => {
    setSelectedRun(row)
    setDocumentsModalOpen(true)
  }

  const handleDocumentsCloseModal = () => {
    setDocumentsModalOpen(false)
    setSelectedRun(null)
  }

  useEffect(() => {
    if (!company) return

    // Subscribe to the runs collection
    const unsubscribe = subscribeToAuditorRuns(
      company.id,
      (runsList) => {
        // Handle the updated runs list
        const formattedRunsList = runsList.map((run) => ({
          ...run,
          status: run.status === 'Done Successfully' ? 'Done' : run.status,
          run_datetime: new Date(run.run_datetime.seconds * 1000).toLocaleString(),
          state:
            run.rationale?.location?.county && run.rationale?.location?.state
              ? run.rationale?.location?.state + '/' + run.rationale?.location?.county
              : !isNaN(run.rationale?.location?.state + run.rationale?.location?.county)
                ? run.rationale?.location?.state + run.rationale?.location?.county
                : '',
          approved: run.approved == undefined ? '' : run.approved ? 'Approved' : 'Rejected',
          vin: run.rationale?.vin,
          organization_name: run.rationale?.organization_name,
        }))
        setAuditorRuns(formattedRunsList)
        setLoading(false)
      },
      (error) => {
        console.error('Error fetching runs data:', error)
        setLoading(false)
      }
    )

    return () => unsubscribe()
  }, [company])

  const handleFormClose = () => {
    setFormOpen(false)
    setAllFiles([])
    setSelectedFiles([])
    setInputValue('')
    setActiveStep(0)
  }

  const handleFileUpload = async ({ url, gsUrl, filename, docId }) => {
    const newFile = await uploadFile({
      companyId: company.id,
      toolName: 'AvaAuditor',
      url,
      gsUrl,
      filename,
      docId,
      uploadedBy: user.name,
      uploadedAt: new Date(),
    })
    setAllFiles((prevState) => [...prevState, newFile])
    setSelectedFiles((prevState) => [...prevState, newFile])
  }

  const handleNext = () => {
    if (activeStep === 0 && !selectedFiles.length) return
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleAddAuditorRun = async () => {
    setIsSubmitting(true)
    setFormOpen(false)

    const runData = {
      name: !inputValue ? selectedFiles[0].filename : inputValue,
      customer_id: company.id,
      status: 'Created',
      run_datetime: new Date(),
      user_name: user.name,
      user_id: user.uid,
      files: selectedFiles.map((file) => ({
        filename: file.filename,
        url: file.url,
      })),
    }
    // Use the utility function to add the Auditor Run
    const runDocRef = await addAuditorRun({
      ...runData,
    })
    try {
      if (!runDocRef) {
        console.error('Failed to create auditor run document.')
        return
      }

      // Use the utility function to update each file
      for (const file of selectedFiles) {
        await updateDocument(['customers', company.id, 'tools', 'AvaAuditor', 'files'], file.id, {
          run_id: runDocRef.id,
        })
      }

      const requestData = {
        user_id: user.uid,
        customer_id: company.id,
        tool_run_id: runDocRef.id,
        input_images: selectedFiles,
      }

      // TODO: temporary fix until we refactor the services to return a promise.
      setIsSubmitting(false)
      setFormOpen(false)
      setSelectedFiles([])
      setAllFiles([])
      setActiveStep(0)

      const response = await startAvaAuditorRun(requestData)
      const data = await response.json()

      // TODO: we could centralize this error handling to avoid repeating it in every tool.
      if (response.ok) {
        // TODO: when the error handling is centralized, we can take the opportunity to use
        //       a popup instead of an alert for both success and error messages.
        alert(`Success: ${data.message}`)
      } else {
        throw new Error(data.message || 'Failed to start AvaAuditor run')
      }
    } catch (error) {
      console.error('Error processing request: ', error)
      await updateDocument(['customers', company.id, 'tools', 'AvaAuditor', 'runs'], runDocRef.id, {
        status: 'Failed',
        result: '',
      })
    }
  }

  const handleSelectDocument = (event, values) => {
    setSelectedFiles(values)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6">Runs</Typography>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => setFormOpen(true)}>
              Add
            </Button>
          </Box>
          <Box
            sx={{
              minHeight: 400,
              width: '100%',
              overflowX: 'auto',
              overflowY: 'auto',
              paddingBottom: '0px',
              '&::-webkit-scrollbar': {
                width: '12px',
                height: '12px',
              },
              '&::-webkit-scrollbar-track': {
                background: theme.palette.background.secondary,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.background.primary,
                borderRadius: '10px',
                border: `3px solid ${theme.palette.background.secondary}`,
              },
              '& *': {
                scrollbarWidth: 'thin',
                scrollbarColor: `${theme.palette.background.primary} ${theme.palette.background.secondary}`,
              },
            }}
          >
            <CustomDataGrid
              rows={auditorRuns}
              columns={auditorRunsColumns}
              pageSize={20}
              loading={loading}
              disableRowSelectionOnClick={true}
              autoHeight
            />
          </Box>
        </Grid>
      </Grid>

      <Modal open={formOpen} onClose={handleFormClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : '50%',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6">Add Ava Auditor Run</Typography>
            <IconButton onClick={handleFormClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {index === 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Autocomplete
                          multiple
                          disablePortal
                          id="documents-select"
                          options={allFiles}
                          value={selectedFiles}
                          getOptionLabel={(option) => option.filename}
                          onChange={handleSelectDocument}
                          renderInput={(params) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <TextField {...params} label="Select documents" sx={{ flexGrow: 1 }} />
                            </Box>
                          )}
                        />
                        <TextField
                          label="Optional name"
                          name="name"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FileUpload
                          user={user}
                          multiple="true"
                          onSuccess={({ url, gsUrl, filename, docId }) =>
                            handleFileUpload({ url, gsUrl, filename, docId })
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={!selectedFiles.length}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>All steps completed - you're finished</Typography>
              <Button onClick={handleFormClose} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                  onClick={handleAddAuditorRun}
                >
                  Add Run
                </Button>
                {isSubmitting && <CircularProgress size={24} sx={{ ml: 2 }} />}
              </Box>
            </Paper>
          )}
        </Box>
      </Modal>

      <Modal
        open={rationaleModalOpen}
        onClose={handleRationaleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6" id="modal-modal-title">
              Rationale - {selectedRun?.id}{' '}
            </Typography>
            <IconButton onClick={handleRationaleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedRun && (
            <Box>
              <Table>
                <TableBody>
                  <TableRow key="approved">
                    <TableCell style={selectedRun.approved === 'Approved' ? { color: 'green' } : { color: 'red' }}>
                      <b>{selectedRun.approved}</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {/*
                                    <TableRow key="confidence">
                                        <TableCell><b>CONFIDENCE</b></TableCell>
                                        <TableCell>{selectedRun.confidence.toString()}</TableCell>
                                    </TableRow>
                                    */}
                  <TableRow key="next_steps">
                    <TableCell>
                      <b>ITEMS FOR REVIEW</b>
                    </TableCell>
                    <TableCell>
                      <List sx={{ listStyleType: 'disc' }}>
                        {Object.keys(selectedRun.next_steps || {}).map((step) => (
                          <ListItem key={step} sx={{ display: 'list-item' }}>
                            {selectedRun.next_steps[step]}
                          </ListItem>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                  <TableRow key="location">
                    <TableCell style={{ borderBottom: 'none' }}>
                      <b>LOCATION</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }}></TableCell>
                  </TableRow>
                  <TableRow key="state">
                    <TableCell>state</TableCell>
                    <TableCell>{selectedRun.rationale ? selectedRun.rationale.location['state'] : undefined}</TableCell>
                  </TableRow>
                  <TableRow key="county">
                    <TableCell>county</TableCell>
                    <TableCell>
                      {selectedRun.rationale ? selectedRun.rationale.location['county'] : undefined}
                    </TableCell>
                  </TableRow>
                  <TableRow key="description">
                    <TableCell>description</TableCell>
                    <TableCell>
                      {selectedRun.rationale ? selectedRun.rationale.location['description'] : undefined}
                    </TableCell>
                  </TableRow>
                  <TableRow key="requirements">
                    <TableCell style={{ borderBottom: 'none' }}>
                      <b>DETAILED ANALYSIS</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }}></TableCell>
                  </TableRow>
                  {Object.keys(selectedRun.rationale?.requirements || {}).map((key) => (
                    <>
                      <TableRow key={selectedRun.rationale.requirements[key].name}>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <b>{selectedRun.rationale.requirements[key].name}</b>
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }}></TableCell>
                      </TableRow>
                      <TableRow key="description">
                        <TableCell>description</TableCell>
                        <TableCell>{selectedRun.rationale.requirements[key]['description']}</TableCell>
                      </TableRow>
                      <TableRow key="analysis">
                        <TableCell>analysis</TableCell>
                        <TableCell>{selectedRun.rationale.requirements[key].details['analysis']}</TableCell>
                      </TableRow>
                      <TableRow key="required">
                        <TableCell>required</TableCell>
                        <TableCell>{selectedRun.rationale.requirements[key].details['required'].toString()}</TableCell>
                      </TableRow>
                      <TableRow key="met">
                        <TableCell>met</TableCell>
                        <TableCell>{selectedRun.rationale.requirements[key]['met'].toString()}</TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </Modal>

      <FeedbackModal
        open={feedbackModalOpen}
        onClose={handleFeedbackCloseModal}
        tool="AvaAuditor"
        selectedRun={selectedRun}
        showCorrectAnswerDropdown={true}
        correctAnswerOptions={[
          { value: 'approved', label: 'Case Approved' },
          { value: 'rejected', label: 'Case Rejected' },
        ]}
        showRejectionReasons={true}
        rejectionReasonOptions={[
          { value: 'Missing Document', label: 'Missing Document' },
          { value: 'Expired Document', label: 'Expired Document' },
          {
            value: 'Inconsistent VIN, name or other important information',
            label: 'Inconsistent VIN, name or other important information',
          },
          { value: 'Application filled wrongly', label: 'Application filled wrongly' },
          { value: 'Other', label: 'Other' },
        ]}
        showAdditionalFeedback={true}
        shouldMakeFetch={true}
      />

      <Modal
        open={documentsModalOpen}
        onClose={handleDocumentsCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6" id="modal-modal-title">
              Documents - {selectedRun?.id}{' '}
            </Typography>
            <IconButton onClick={handleDocumentsCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedRun && (
            <Box>
              <Table>
                <TableBody>
                  {Object.keys(selectedRun.files || {}).map((key) => (
                    <TableRow key={key}>
                      <TableCell>{selectedRun.files[key].filename}</TableCell>
                      <TableCell>
                        <IconButton
                          component="a"
                          href={selectedRun.files[key].url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  )
}

export default AvaAuditor
