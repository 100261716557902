import { useEffect, useState } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { useMode, ColorModeContext } from './theme'
import { UserProvider } from './contexts/UserContext'
import { Routes, Route, useLocation } from 'react-router-dom'
import Topbar from './scenes/global/Topbar'
import Sidebar from './scenes/global/Sidebar'
import ModalSignIn from './scenes/global/ModalSignIn'
import IntegrationsDataUpload from './scenes/IntegrationsDataUpload'
import Insights from './scenes/Insights'
import Settings from './scenes/Settings'
import Onboarding from './scenes/global/Onboarding'
import Vehicles from './scenes/Vehicles'
import Vehicle from './scenes/Vehicles/Vehicle'
import GroupEdit from './scenes/Vehicles/GroupEdit'
import DriverEdit from './scenes/Vehicles/DriverEdit'
import Tools from './scenes/Tools'
import SuperAdmin from './scenes/SuperAdmin'
import { Box } from '@mui/material'
import PrivateRoute from './scenes/global/PrivateRoute'

function App() {
  const { theme, toggleColorMode } = useMode()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [signInOpen, setSignInOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const openSignInForRoute = location.pathname === '/signIn/finishSignUp'
    setSignInOpen(openSignInForRoute)
  }, [location])

  return (
    <UserProvider>
      <ColorModeContext.Provider value={{ theme, toggleColorMode }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* <Onboarding setSignInOpen={setSignInOpen} /> */}
          {/* <ModalSignIn signInOpen={signInOpen} setSignInOpen={setSignInOpen} /> */}
          <div className="app">
            <Sidebar isCollapsed={isCollapsed} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
                backgroundColor: theme.palette.background.primary,
              }}
              className="content"
            >
              <Topbar
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                setIsDrawerOpen={setIsDrawerOpen}
                signInOpen={signInOpen}
                setSignInOpen={setSignInOpen}
              />
              <Routes>
                <Route path="/signIn" element={<ModalSignIn signInOpen={true} setSignInOpen={setSignInOpen} />} />
                <Route path="/finishSignIn" element={<ModalSignIn signInOpen={true} setSignInOpen={setSignInOpen} />} />
                <Route
                  path="/companyOnboarding"
                  element={<ModalSignIn signInOpen={true} setSignInOpen={setSignInOpen} />}
                />
                <Route
                  path="/companyInvite/:inviteId"
                  element={<ModalSignIn signInOpen={true} setSignInOpen={setSignInOpen} />}
                />
                <Route path="/" element={<PrivateRoute element={<Vehicles />} />} />
                <Route path="/integrations" element={<PrivateRoute element={<IntegrationsDataUpload />} />} />
                <Route path="/dashboard" element={<PrivateRoute element={<Insights />} />} />
                <Route path="/settings/*" element={<PrivateRoute element={<Settings />} />} />
                <Route path="/vehicle/*" element={<PrivateRoute element={<Vehicle />} />} />
                <Route path="/edit-group/*" element={<PrivateRoute element={<GroupEdit />} />} />
                <Route path="/edit-driver/*" element={<PrivateRoute element={<DriverEdit />} />} />
                <Route path="/vehicles/*" element={<PrivateRoute element={<Vehicles />} />} />
                <Route path="/tools/*" element={<PrivateRoute element={<Tools />} />} />
                <Route path="/superadmin/*" element={<PrivateRoute element={<SuperAdmin />} />} />
                <Route path="*" element={<PrivateRoute element={<Vehicles />} />} />
              </Routes>
            </Box>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </UserProvider>
  )
}

export default App
