import { orderBy, query } from 'firebase/firestore'
import { createDocument, setCollection, subscribeToCollection } from '../databaseUtils'

export const addAuditorRun = async ({ customer_id, name, status, run_datetime, user_name, user_id, files }) => {
  const newRunFile = {
    name,
    customer_id,
    status,
    run_datetime,
    user_name,
    user_id,
    files,
  }

  const collectionPath = ['customers', customer_id, 'tools', 'AvaAuditor', 'runs']

  const result = await createDocument(collectionPath, newRunFile)
  return result ? { id: result.id } : null
}

const queryRuns = (companyId) =>
  query(setCollection(['customers', companyId, 'tools', 'AvaAuditor', 'runs']), orderBy('run_datetime', 'desc'))

export const subscribeToAuditorRuns = (companyId, onUpdate, onError) =>
  subscribeToCollection(queryRuns(companyId), onUpdate, onError)
