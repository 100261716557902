import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { fetchGroups, updateVehicleGroup, deleteGroup } from '../../database/services/groups'
import { Box, FormControl, TextField, Select, MenuItem, InputLabel, Button } from '@mui/material'
import { LanguagesPreferences } from './languagesPreferences'

const GroupEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { user } = useUser()
  const [group, setGroup] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const groupsData = await fetchGroups(user.companyId)
        const groupToEdit = groupsData.find((group) => group.id === id)
        setGroup(groupToEdit)
      } catch (error) {
        console.error('Error fetching group data: ', error)
      }
    }

    if (id !== 'new') {
      fetchGroupData()
    }
  }, [id, user.companyId])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setGroup((prevGroup) => ({ ...prevGroup, [name]: value }))
  }

  const handleSave = async () => {
    try {
      // Save logic here, e.g., update the group in Firebase
      // Assuming you have a function to update the group in the `groups.js` service
      await updateVehicleGroup(user.companyId, group.id, group.groupID, group.groupName)
      navigate('/groups')
    } catch (error) {
      console.error('Error saving group: ', error)
    }
  }

  return (
    <Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <TextField
          label="Contact Name"
          name="contactName"
          value={group?.contactName || ''}
          onChange={handleInputChange}
          InputProps={{ readOnly: !isEditing }}
          variant="outlined"
          required
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <TextField
          label="Contact's Email"
          name="ownerEmail"
          value={group?.ownerEmail || ''}
          onChange={handleInputChange}
          InputProps={{ readOnly: !isEditing }}
          variant="outlined"
          required
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <TextField
          label="Contact's Phone (Optional)"
          name="ownerPhone"
          value={group?.ownerPhone || ''}
          onChange={handleInputChange}
          InputProps={{ readOnly: !isEditing }}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="languagepreferenceoptional">Language Preference (Optional)</InputLabel>
        <Select
          labelId="language-label"
          id="language-select"
          name="languagePreference"
          value={group?.languagePreference || 'en'}
          onChange={handleInputChange}
          label="languagepreferenceoptional"
          inputProps={{ readOnly: !isEditing }}
          variant="outlined"
        >
          {LanguagesPreferences.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              {language.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button onClick={handleSave} variant="contained" color="primary">
        Save
      </Button>
    </Box>
  )
}

export default GroupEdit
