import { doc, collection, getDoc, getDocs, addDoc, updateDoc, deleteDoc, query, where } from 'firebase/firestore'
import { db } from '../../firebaseConfig'
import { checkDocumentExists, setCollection, setDocument, subscribeToCollection } from '../databaseUtils'
import { calculateVehicleInfo, formatDateToYYYYMMDD } from '../../scenes/Vehicles/vehicleUtils'

export const existingVehicle = async (companyId, vehicleVin) =>
  getDoc(doc(db, 'customers', companyId, 'decodeVIN', vehicleVin))

export const fetchVehicleById = async (companyId, vehicleId) => {
  try {
    const collectionPath = ['customers', companyId, 'vehicles']
    const vehicleDoc = await checkDocumentExists(collectionPath, vehicleId)
    if (vehicleDoc) {
      return vehicleDoc.data() // Return the data if the document exists
    } else {
      console.error('No such vehicle document!')
      return null // Return null if the document does not exist
    }
  } catch (error) {
    console.error('Error fetching vehicle: ', error)
    throw error // Rethrow the error to be handled by the calling function
  }
}

export const saveVehicleDocument = async (companyId, vehicleId, vehicleData) => {
  try {
    const collectionPath = ['customers', companyId, 'vehicles']
    const isSaved = await setDocument(collectionPath, vehicleId, vehicleData)

    if (!isSaved) {
      console.error('Failed to save the vehicle document.')
    }
    return true // Indicate success
  } catch (error) {
    console.error('Error saving vehicle document:', error)
    throw error // Rethrow the error to be handled by the calling function
  }
}

// Function to fetch vehicles data with a subscription
export const subscribeToVehicles = (companyId, onUpdate, onError) => {
  try {
    const collectionPath = ['customers', companyId, 'vehicles']
    const vehiclesCollection = setCollection(collectionPath)

    const q = query(vehiclesCollection)

    return subscribeToCollection(q, onUpdate, onError)
  } catch (error) {
    console.error('Error subscribing to vehicles data:', error)
    onError(error)
    return null
  }
}

export const fetchVehiclesInfo = async (companyId) => {
  const vehiclesCollection = collection(db, 'customers', companyId, 'vehicles')
  const vehiclesSnapshot = await getDocs(vehiclesCollection)
  const vehiclesList = vehiclesSnapshot.docs.map((doc) => {
    const vehicleData = doc.data()
    const vehicleInfo = calculateVehicleInfo(vehicleData)
    return {
      id: doc.id,
      ...vehicleData,
      status: vehicleInfo.status,
      nextRenewalDate: vehicleInfo.nextDate,
      criticalItem: vehicleInfo.criticalItem,
      recommendedActions: vehicleInfo.recommendedActions,
    }
  })
  return vehiclesList
}

export const fetchVehicles = async (companyId) => {
  try {
    const vehiclesSnapshot = await getDocs(collection(db, 'customers', companyId, 'vehicles'))
    return vehiclesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  } catch (error) {
    console.error('Error fetching vehicles: ', error)
    return []
  }
}

export const fetchVehiclesByDriver = async (companyId, driverId) => {
  try {
    const vehiclesSnapshot = await getDocs(
      query(collection(db, 'customers', companyId, 'vehicles'), where('driverID', '==', driverId))
    )
    return vehiclesSnapshot.docs.map((doc) => doc.id)
  } catch (error) {
    console.error('Error fetching vehicles: ', error)
    return []
  }
}

export const addTask = async (companyId, userId, vehicle, task) => {
  const tasksCollectionRef = collection(db, 'customers', companyId, 'tasks')
  const formattedDate = formatDateToYYYYMMDD(new Date())
  const taskDocRef = await addDoc(tasksCollectionRef, {
    dateStarted: formattedDate,
    task: task.tooltip,
    vehicle: vehicle.carName,
    vehicleID: vehicle.vin,
    description: task.description,
    status: {
      dotColor: 'yellow',
      text: 'Task Queued',
    },
  })
  return taskDocRef.id
}

export const updateVehicleStatus = async (companyId, vin, status, prevStatus = null) => {
  const vehicleDocRef = doc(db, 'customers', companyId, 'vehicles', vin)
  await updateDoc(vehicleDocRef, {
    status,
    ...(prevStatus && { prevStatus }),
  })
}

export const deleteVehicle = async (companyId, vin) => {
  const vehicleDocRef = doc(db, 'customers', companyId, 'vehicles', vin)
  await deleteDoc(vehicleDocRef)
}
