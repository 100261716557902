import React, { useState, useEffect } from 'react'
import { Box, Typography, Button, useTheme } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import Header from '../../components/Header'

const IntegrationsDataUpload = () => {
  const theme = useTheme()

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="INTEGRATIONS & DATA UPLOAD" subtitle="Coming soon..." />
      </Box>
      <Box mt={2} mb={4}>
        <Box display="flex" alignItems="center">
          <CloudUploadIcon style={{ marginRight: '10px' }} />
          <Typography variant="h6">Upload Information</Typography>
        </Box>
        <Button
          variant="outlined"
          style={{
            display: 'block',
            marginTop: '10px',
            borderColor: theme.palette.text.primary,
            color: theme.palette.text.primary,
            height: '80px', // twice the height
            width: 'fit-content',
            padding: '0 20px', // wider padding
          }}
        >
          Upload Vehicle CSV
        </Button>
        <Typography variant="body2" color={theme.palette.grey[300]} style={{ marginTop: '5px' }}>
          Upload a CSV with vehicle information in the following format: vehicle name, VIN, license plate, group
        </Typography>
        <Button
          variant="outlined"
          style={{
            display: 'block',
            marginTop: '20px',
            borderColor: theme.palette.text.primary,
            color: theme.palette.text.primary,
            height: '80px', // twice the height
            width: 'fit-content',
            padding: '0 20px', // wider padding
          }}
        >
          Drag & Drop PDF/Images
        </Button>
        <Typography variant="body2" color={theme.palette.grey[300]} style={{ marginTop: '5px' }}>
          Upload in bulk vehicle documentation: insurance, registration
        </Typography>
      </Box>

      <Box mt={7}>
        {' '}
        {/* Added space before integration section */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <IntegrationInstructionsIcon style={{ marginRight: '10px' }} />
            <Typography variant="h6">Integrate with your Fleet Management System</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          <Typography variant="body2" color={theme.palette.grey[300]}></Typography>
        </Box>
        <Box mt={2}>
          <img src="/fleetio.png" alt="Fleetio" style={{ width: '100px', marginBottom: '20px' }} />
          <img
            src="tmt-fleet-maintenance.png"
            alt="TMT Fleet Maintenance"
            style={{ width: '100px', marginBottom: '20px' }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default IntegrationsDataUpload
