import { getAuth } from 'firebase/auth'

const getIdToken = async () => {
  const auth = getAuth()
  const user = auth.currentUser
  if (user) {
    return user.getIdToken()
  }
  throw new Error('No user is signed in')
}

const fetchWithAuth = async (url, options = {}) => {
  const token = await getIdToken()
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
  }

  const mergedHeaders = { ...headers, ...(options.headers || {}) }

  const response = await fetch(url, {
    ...options,
    headers: mergedHeaders,
    mode: 'cors',
  })

  if (!response.ok) {
    const contentType = response.headers.get('Content-Type')
    if (contentType && contentType.includes('application/problem+json')) {
      const errorData = await response.json()
      alert(errorData.detail || 'An error occurred')
      throw new Error(errorData.detail || 'An error occurred')
    } else {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
  }

  return response
}

export const submitComplianceFeedback = async (requestData) => {
  const url = process.env.REACT_APP_GCF_COMPLIANCE
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startAvaPdfRun = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tools/avapdf`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startAvaSmsRun = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tools/avasms`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startAvaWebRun = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tools/avaweb`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startRegistrationRenewal = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tasks/start-registration-renewal`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const scheduleInspection = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tasks/schedule-inspection`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const startAvaAuditorRun = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/tools/avaauditor`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const retryProcess = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/retry`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}

export const submitFeedback = async (requestData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/feedback`
  return fetchWithAuth(url, {
    method: 'POST',
    body: JSON.stringify(requestData),
  })
}
