import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Modal,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Autocomplete,
  useMediaQuery,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { useColorMode } from '../../theme'
import { addPdfRun, deletePdfRun, setPdfRunStatus, subscribeToPdfRuns } from '../../database/services/avaPdf'
import { useUser } from '../../contexts/UserContext'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined'
import FileUpload from '../global/FileUpload'
import { readCollection, uploadFile } from '../../database/databaseUtils'
import FeedbackModal from '../../components/FeedbackModal' // Adjust the relative path as needed
import { startAvaPdfRun } from '../../database/services/fetchFairway'

const AvaPDF = () => {
  const { theme } = useColorMode()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user, company } = useUser()
  const [pdfRuns, setPdfRuns] = useState([])
  const [loading, setLoading] = useState(true)
  const [formOpen, setFormOpen] = useState(false)
  const [formData, setFormData] = useState({
    inputPDF: '',
    vehicleId: '',
    additionalInstructions: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Upload or Select a PDF', 'Select a Vehicle', 'Additional Instructions']
  const [pdfFiles, setPdfFiles] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const [selectedRun, setSelectedRun] = useState(null)

  const getStatusColor = (status) => {
    switch (status) {
      case 'Done':
        return '#00FF00'
      case 'Failed':
      case 'Error':
        return '#FF0000'
      case 'In Progress':
      case 'Created':
        return '#FFFF00'
      default:
        return '#FFFF00'
    }
  }

  const actionIcons = {
    leaveFeedback: { tooltip: 'Leave Feedback', icon: <ReviewsOutlinedIcon /> },
  }

  const pdfRunsColumns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" mt="15px">
          <Box
            sx={{
              width: 10,
              height: 10,
              bgcolor: getStatusColor(params.value),
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: 1,
            }}
          />
          <Typography variant="body2">{params.value}</Typography>
        </Box>
      ),
    },
    { field: 'run_datetime', headerName: 'Run Time', width: 200 },
    { field: 'user_name', headerName: 'User Name', width: 130 },
    {
      field: 'target_pdf',
      headerName: 'PDF',
      width: 150,
      renderCell: (params) => (
        <IconButton
          component="a"
          href={params.value ? params.value : undefined}
          target="_blank"
          rel="noopener noreferrer"
          disabled={!params.value}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: 'completed_pdf',
      headerName: 'Completed PDF',
      width: 150,
      renderCell: (params) => (
        <IconButton
          component="a"
          href={params.value ? params.value : undefined}
          target="_blank"
          rel="noopener noreferrer"
          disabled={!params.value}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: 'vehicle_id',
      headerName: 'Vehicle',
      width: 180,
      renderCell: (params) => (
        <a href={`/vehicle/${params.value}`} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
          {params.value}
        </a>
      ),
    },
    {
      field: 'additional_instructions',
      headerName: 'Additional Instructions',
      width: 180,
    },
    {
      field: 'rationale',
      headerName: 'Rationale',
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => handleView(params.row)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 180,
    },
    {
      field: 'moreActions',
      headerName: 'More Actions',
      width: 180,
      renderCell: (params) => (
        <Box display="flex">
          {Object.keys(actionIcons).map((action) => (
            <Tooltip key={action} title={actionIcons[action].tooltip}>
              <IconButton onClick={() => handleActionView(action, params.row)}>{actionIcons[action].icon}</IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    },
  ]

  const handleActionView = (action, row) => {
    switch (action) {
      case 'leaveFeedback':
        setSelectedRun(row)
        setFeedbackModalOpen(true)
        break

      default:
        console.error(`Action not implemented: ${action}`)
        alert('Action not implemented')
    }
  }

  const handleFeedbackCloseModal = () => {
    setFeedbackModalOpen(false)
    setSelectedRun(null)
  }

  const handleView = (row) => {
    setSelectedRun(row)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedRun(null)
  }

  useEffect(() => {
    if (!company) return

    // Subscribe to the runs collection
    const unsubscribe = subscribeToPdfRuns(
      company.id,
      (runsList) => {
        // Handle the updated runs list
        const formattedRunsList = runsList.map((run) => ({
          ...run,
          status: run.status === 'Done Successfully' ? 'Done' : run.status,
          run_datetime: new Date(run.run_datetime.seconds * 1000).toLocaleString(),
        }))
        setPdfRuns(formattedRunsList)
        setLoading(false)
      },
      (error) => {
        console.error('Error fetching runs data:', error)
        setLoading(false)
      }
    )

    // Fetch PDF files and vehicles using utility function
    const fetchData = async () => {
      const [pdfFiles, vehicles] = await Promise.all([
        readCollection(['customers', company.id, 'tools', 'AvaPDF', 'files']),
        readCollection(['customers', company.id, 'vehicles']),
      ])

      setPdfFiles(pdfFiles)
      setVehicles(vehicles)
    }

    fetchData()

    return () => unsubscribe()
  }, [company])

  const handleFormClose = () => {
    setFormOpen(false)
    setFormData({ inputPDF: '', vehicleId: '', additionalInstructions: '' })
    setActiveStep(0)
  }

  const handleFileUpload = async ({ url, gsUrl, filename, docId }) => {
    const newFile = await uploadFile({
      companyId: company.id,
      toolName: 'AvaPDF',
      url,
      gsUrl,
      filename,
      docId,
      uploadedBy: user.name,
      uploadedAt: new Date(),
    })
    setPdfFiles((prevState) => [...prevState, newFile])
    setFormData((prevState) => ({ ...prevState, inputPDF: newFile }))
  }

  const handleNext = () => {
    if (activeStep === 0 && !formData.inputPDF) return
    if (activeStep === 1 && !formData.vehicleId) return
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleDeleteFile = async (fileId) => {
    await deletePdfRun(company.id, fileId)
    setPdfFiles((prevState) => prevState.filter((file) => file.id !== fileId))
  }

  const handleAddPdfRun = async () => {
    setIsSubmitting(true)
    setFormOpen(false)
    const runDocRef = await addPdfRun({
      customer_id: company.id,
      vehicle_id: formData.vehicleId,
      additional_instructions: formData.additionalInstructions,
      status: 'Created',
      run_datetime: new Date(),
      user_name: user.name,
      user_id: user.uid,
      target_pdf: formData.inputPDF.url,
      completed_pdf: null,
    })
    try {
      console.log('runDocRef', runDocRef)

      const requestData = {
        customer_id: company.id,
        tool_run_id: runDocRef.id,
        input_pdf: formData.inputPDF,
        vehicle_id: formData.vehicleId,
        additional_instructions: formData.additionalInstructions,
      }

      // TODO: temporary fix until we refactor the services to return a promise.
      setFormData({
        inputPDF: '',
        vehicleId: '',
        additionalInstructions: '',
      })
      setIsSubmitting(false)
      setFormOpen(false)
      setActiveStep(0)

      const response = await startAvaPdfRun(requestData)
      const data = await response.json()

      // TODO: we could centralize this error handling to avoid repeating it in every tool.
      if (response.ok) {
        // TODO: when the error handling is centralized, we can take the opportunity to use
        //       a popup instead of an alert for both success and error messages.
        alert(`Success: ${data.message}`)
      } else {
        throw new Error(data.message || 'Failed to start AvaPDF run')
      }
    } catch (error) {
      console.error('Error processing request: ', error)
      await setPdfRunStatus(company.id, runDocRef.id, 'Failed')
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSelectPdf = (event, value) => {
    if (value) {
      setFormData((prevState) => ({ ...prevState, inputPDF: value }))
    }
  }

  const handleSelectVehicle = (event, value) => {
    if (value) {
      setFormData((prevState) => ({ ...prevState, vehicleId: value.id }))
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6">Runs</Typography>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => setFormOpen(true)}>
              Add
            </Button>
          </Box>
          <Box
            sx={{
              minHeight: 400,
              width: '100%',
              overflowX: 'auto',
              overflowY: 'auto',
              paddingBottom: '0px',
              '&::-webkit-scrollbar': {
                width: '12px',
                height: '12px',
              },
              '&::-webkit-scrollbar-track': {
                background: theme.palette.background.secondary,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.background.primary,
                borderRadius: '10px',
                border: `3px solid ${theme.palette.background.secondary}`,
              },
              '& *': {
                scrollbarWidth: 'thin',
                scrollbarColor: `${theme.palette.background.primary} ${theme.palette.background.secondary}`,
              },
            }}
          >
            <CustomDataGrid
              rows={pdfRuns}
              columns={pdfRunsColumns}
              pageSize={20}
              loading={loading}
              disableRowSelectionOnClick={true}
              autoHeight
            />
          </Box>
        </Grid>
      </Grid>

      <Modal open={formOpen} onClose={handleFormClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : '50%',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6">Add Ava PDF Run</Typography>
            <IconButton onClick={handleFormClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  {label}{' '}
                  {activeStep > index &&
                    (index === 0 ? formData.inputPDF.filename : index === 1 ? formData.vehicleId : 'Completed')}
                </StepLabel>
                <StepContent>
                  {index === 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Upload or Select a PDF</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          disablePortal
                          id="pdf-select"
                          options={pdfFiles}
                          getOptionLabel={(option) => option.filename}
                          onChange={handleSelectPdf}
                          renderInput={(params) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <TextField {...params} label="Select a PDF" sx={{ flexGrow: 1 }} />
                            </Box>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FileUpload
                          user={user}
                          onSuccess={({ url, gsUrl, filename, docId }) =>
                            handleFileUpload({ url, gsUrl, filename, docId })
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  {index === 1 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Select a Vehicle</Typography>
                        <Autocomplete
                          disablePortal
                          id="vehicle-select"
                          options={vehicles}
                          getOptionLabel={(option) => option.id}
                          onChange={handleSelectVehicle}
                          renderInput={(params) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <TextField {...params} label="Vehicle" sx={{ flexGrow: 1 }} />
                            </Box>
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {index === 2 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Additional Instructions</Typography>
                        <TextField
                          label="Additional Instructions"
                          name="additionalInstructions"
                          value={formData.additionalInstructions}
                          onChange={handleInputChange}
                          fullWidth
                          multiline
                          rows={5}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={index === 0 ? !formData.inputPDF : index === 1 ? !formData.vehicleId : false}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>All steps completed - you're finished</Typography>
              <Button onClick={handleFormClose} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                  onClick={handleAddPdfRun}
                >
                  Add Run
                </Button>
                {isSubmitting && <CircularProgress size={24} sx={{ ml: 2 }} />}
              </Box>
            </Paper>
          )}
        </Box>
      </Modal>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6" id="modal-modal-title">
              Rationale - {selectedRun?.id}{' '}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedRun && (
            <Box>
              <Table>
                <TableBody>
                  {Object.keys(selectedRun.rationale || {}).map((key) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{selectedRun.rationale[key]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </Modal>

      <FeedbackModal
        open={feedbackModalOpen}
        onClose={handleFeedbackCloseModal}
        tool="AvaPDF"
        selectedRun={selectedRun}
        showCorrectAnswerDropdown={false}
        showRejectionReasons={false}
        showAdditionalFeedback={true}
        shouldMakeFetch={false}
      />
    </Box>
  )
}

export default AvaPDF
